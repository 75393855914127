import React from 'react'
import {  graphql } from 'gatsby'
import BuildPostList from '../components/buildPostList'
class BlogIndex extends React.Component {

   
  render() {
    const { data } = this.props
    const posts = data.allPrismicPost.edges 

   
    return (
     
       <BuildPostList posts={posts} pageContext={this.props.pageContext} tag={''}/>


    )
  }
}

export default BlogIndex

export const pageQuery = graphql`query blogPageQuery($skip: Int!, $limit: Int!) {
  allPrismicPost( skip: $skip,  limit: $limit, sort: { order: DESC, fields: first_publication_date }) {
    edges {
    
      node {
        tags
        first_publication_date
        last_publication_date
        data {
        

          description {
            html
            text
          }
        
          teaser_image {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(width: 600)
              }
            }
          }
          title {
            html
            text
          }
        }
        tags
        uid
       
      }
    }
  }
 
}

`
